import i18n from '@/i18n';
import { STATUS_FORM } from './constants';

const handleStatus = (status, list = STATUS_FORM) => {
    if (!status) return '';
    return list.find((s) => s.id == status)?.value ?? '';
};

const handleAddConfigURL = (validateArrayURL, url_parameters) => {
    validateArrayURL = url_parameters.map((o, idx) => {
        const varConfigValue = validateArrayURL[idx]?.configFieldUrl;
        return {
            configFieldUrl: {
                isRequired: varConfigValue?.isRequired ?? false,
                error: varConfigValue?.error ?? false,
                errorField: varConfigValue?.errorField ?? i18n.t('managerForm.settingBasic.urlParam'),
                errorCustomMess: varConfigValue?.errorCustomMess ?? '',
                valueSync: o.url_parameter ? o.url_parameter : '',
                typesValidate: varConfigValue?.typesValidate ?? ['required', 'max255', 'sub-domain']
            }
        };
    });

    return validateArrayURL;
};

const handleDataBeforeSendServer = (formData) => {
    return {
        ...(formData.id !== null &&
            formData.id !== undefined && {
                id: formData.id
            }),
        campaign: formData?.campaign?.id
            ? {
                  id: formData?.campaign?.id
              }
            : null,
        client: formData?.client?.id
            ? {
                  id: formData?.client?.id
              }
            : null,
        name: formData.name,
        url_public: formData.url_public,
        status: formData.status,
        application_limit: formData.application_limit,
        url_redirect_when_limit: formData.url_redirect_when_limit,
        reservation_display_url: formData.reservation_display_url,
        expired_display_url: formData.expired_display_url,
        mail_to: formData.mail_to,
        save_form_temporarily: formData.save_form_temporarily,
        temporary_expiration_date: formData.temporary_expiration_date,
        hashtag: formData.hashtag,
        url_parameters: formData.url_parameters
    };
};

const handleDataResponse = (dataForm, data) => {
    dataForm.id = data?.id ?? null;
    dataForm.campaign = data?.campaign ?? null;
    dataForm.client = data?.client ?? null;
    dataForm.name = data?.name ?? '';
    dataForm.url_public = data?.url_public ?? '';
    dataForm.status = data?.status ?? '';
    dataForm.application_limit = data?.application_limit ?? '';
    dataForm.url_redirect_when_limit = data?.url_redirect_when_limit ?? '';
    dataForm.reservation_display_url = data?.reservation_display_url ?? '';
    dataForm.expired_display_url = data?.expired_display_url ?? '';
    dataForm.mail_to = data?.mail_to ?? '';
    dataForm.save_form_temporarily = data?.save_form_temporarily ?? '';
    dataForm.temporary_expiration_date = data?.temporary_expiration_date ?? '';
    dataForm.hashtag = data?.hashtag ?? '';
    dataForm.url_parameters = data?.url_parameters ?? '';

    return dataForm;
};

export { handleAddConfigURL, handleDataBeforeSendServer, handleDataResponse, handleStatus };
