<template>
    <div>
        <ModalCommon :id="idInfo" :config="configModalCommonInfo" @close="handleCloseInfomation()" :isView="true">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="shippingCompany">{{ this.$t('pageCampaign.tabPrize.shippingCompany') }}</label>
                    <div class="col-sm-9">
                        {{ formInfo.delivery_company }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="trackingNumber">{{ this.$t('pageCampaign.tabPrize.trackingNumber') }}</label>
                    <div class="col-sm-9">
                        {{ formInfo.tracking_number }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="expectedNumberOfBoxesInStock">{{
                        this.$t('pageCampaign.tabPrize.expectedNumberOfBoxesInStock')
                    }}</label>
                    <div class="col-sm-9">
                        {{ formInfo.number_of_boxes_to_be_stocked }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="expirationDate">{{ this.$t('pageCampaign.tabPrize.expirationDate') }}</label>
                    <div class="col-sm-9">
                        {{ formInfo.expiration_date }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="storageWarehouse">{{ this.$t('pageCampaign.tabPrize.storageWarehouse') }}</label>
                    <div class="col-sm-9">
                        {{ formInfo.storage_warehouse?.value ?? '' }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="locatorNumber">{{ this.$t('pageCampaign.tabPrize.locatorNumber') }}</label>
                    <div class="col-sm-9">
                        {{ formInfo.locator_number }}
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import { CONFIG_MODALCOMMONINFO } from '@/components/Campaign/TabPrize/constans';
import ModalCommon from '@/components/Modal/main.vue';

export default {
    name: 'PopUpInfomation',
    components: {
        ModalCommon
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        isShow: {
            type: Boolean,
            default: false
        },
        dataInfo: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            idInfo: this.id,
            formInfo: this.dataInfo,
            configModalCommonInfo: CONFIG_MODALCOMMONINFO
        };
    },
    methods: {
        handleCloseInfomation() {
            this.$emit('update:isShow', false);
        }
    },
    watch: {
        id: {
            handler: function (valId) {
                this.idInfo = valId;
            },
            immediate: true
        },
        title: {
            handler: function (valTitle) {
                this.configModalCommonInfo.title = valTitle;
            },
            immediate: true
        },
        isShow: {
            handler: function (valShow) {
                this.configModalCommonInfo.model = valShow;
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
