import i18n from '@/i18n';
import { state } from '@/state/modules/authfack.js';

const campaignDefault = state?.user?.default_campaign ?? null;

const TABS = [
    {
        id: 1,
        name: '基本設定',
        disabled: false
    },
    {
        id: 2,
        name: 'デザイン設定',
        disabled: false
    },
    {
        id: 3,
        name: 'ページ設定',
        disabled: false
    },
    {
        id: 4,
        name: 'メール設定',
        disabled: false
    },
    {
        id: 5,
        name: '公開設定',
        disabled: false
    },
    {
        id: 6,
        name: 'シリアルコード設定',
        disabled: false
    },
    {
        id: 7,
        name: 'デジタルギフト設定',
        disabled: false
    }
];

const STATUS_FORM = [
    {
        id: 1,
        value: '公開前'
    },
    {
        id: 2,
        value: '終了後'
    },
    {
        id: 3,
        value: '下書き'
    }
];

const OPTIONS_TAG = [
    {
        id: 1,
        value: '個人情報'
    },
    {
        id: 2,
        value: 'プレゼント用'
    }
];

const configs = {
    campaign: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    nameForm: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    client: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    configTable: {
        headers: [
            {
                label: 'No.'
            },
            {
                label: i18n.t('managerForm.list.nameCampaign')
            },
            {
                label: i18n.t('managerForm.list.nameForm')
            },
            {
                label: i18n.t('managerForm.list.urlPublic')
            },
            {
                label: i18n.t('managerForm.list.status')
            },
            {
                label: i18n.t('managerForm.list.schedule')
            }
        ]
    },
    configPage: {
        total: 0,
        limit: 10,
        page: 1
    }
};

const initFormSearch = () => {
    return {
        campaign: null,
        name: null,
        status: [],
        tag: null,
        client: null,
        date_from: {
            campaign_start_date: '',
            campaign_end_date: '',
            secretariat_end_date: ''
        },
        date_to: {
            campaign_start_date: '',
            campaign_end_date: '',
            secretariat_end_date: ''
        }
    };
};

const TEMPORARILY = [
    {
        id: 1,
        value: '無効'
    },
    {
        id: 2,
        value: '有効'
    }
];

const SOCIAL_MEDIA_LOGIN = [
    {
        id: 1,
        name: 'X',
        value: ''
    },
    {
        id: 2,
        name: 'Facebook',
        value: ''
    },
    {
        id: 3,
        name: 'TikTok',
        value: ''
    },
    {
        id: 4,
        name: 'Apple',
        value: ''
    },
    {
        id: 5,
        name: 'Line',
        value: ''
    },
    {
        id: 6,
        name: 'Google',
        value: ''
    },
    {
        id: 7,
        name: 'Yahoo',
        value: ''
    }
];

const initForm = () => {
    return {
        // setting basic
        campaign: campaignDefault,
        client: null,
        name: '',
        status: 1,
        url_public: '',
        url_parameters: [{ url_parameter: '' }],
        application_limit: '',
        url_redirect_when_limit: '',
        mail_to: '',
        save_form_temporarily: 1,
        temporary_expiration_date: '',
        hashtag: [],
        reservation_display_url: '',
        expired_display_url: '',
        setting_login_social: [],
        setting_my_page: false,
        // setting design
        header: '',
        footer: '',
        ga: '',
        title: '',
        description: '',
        // setting page
        setting_page: {
            page_type: null,
            pages: [
                {
                    id: 1,
                    title: '入力画面 Form nhập',
                    page_child: [
                        {
                            id: 1,
                            name_page: 'Page Default Input',
                            is_default: true
                        }
                    ],
                    is_accept_add: true
                },
                {
                    id: 2,
                    title: '確認画面',
                    page_child: [
                        {
                            id: 1,
                            name_page: 'Page Default Confirm',
                            is_default: true
                        }
                    ],
                    is_accept_add: false
                },
                {
                    id: 3,
                    title: '完了画面',
                    page_child: [
                        {
                            id: 1,
                            name_page: 'Page Default Success',
                            is_default: true
                        }
                    ],
                    is_accept_add: false
                }
            ]
        },

        // setting serial-code
        serial_codes: [],
        mail_common: false,
        // setting digital-gift
        title_digital: '',
        top_text_digital: '',
        bottom_text_digital: '',
        selected_item_digital: '',
        probability_of_winning_digital: '',
        result_digital: '',
        rejection_text_digital: ''
    };
};

export { STATUS_FORM, configs, TEMPORARILY, SOCIAL_MEDIA_LOGIN, TABS, OPTIONS_TAG, initForm, initFormSearch };
