<template>
    <div>
        <ModalCommon :id="idInfo" :config="configModalCompatible" @close="handleCloseCompatible()" :isView="true">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ this.$t('pageCampaign.tabPrize.prizeLessStatus') }}</label>
                    <div class="col-sm-9">
                        {{ formCompatible.prize_less_status?.value ?? '' }}
                    </div>
                </div>

                <div v-if="hasPrizeLessStatus(formCompatible)" class="form-group row">
                    <label class="col-sm-3 mw--form" for="reconfirmationDate">{{ this.$t('pageCampaign.tabPrize.reconfirmationDate') }}</label>
                    <div class="col-sm-9">
                        {{ handleFormatDate(formCompatible.reconfirmation_date, 'dateJP') ?? '' }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="prizeResponseInstructions">{{
                        this.$t('pageCampaign.tabPrize.prizeResponseInstructions')
                    }}</label>
                    <div class="col-sm-9">
                        {{ formCompatible.prize_refund_instructions }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="storageFee">{{ this.$t('pageCampaign.tabPrize.storageFee') }}</label>
                    <div class="col-sm-9">
                        {{ formCompatible.preserve_fee }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="storageFeeOccurrenceDate">{{
                        this.$t('pageCampaign.tabPrize.storageFeeOccurrenceDate')
                    }}</label>
                    <div class="col-sm-9">
                        {{ handleFormatDate(formCompatible.date_of_preserve, 'dateJP') ?? '' }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="remarks">{{ this.$t('pageCampaign.tabPrize.remarks') }}</label>
                    <div class="col-sm-9">
                        {{ formCompatible.reference }}
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import { CONFIG_MODALCOMPATIBLE } from '@/components/Campaign/TabPrize/constans';
import { formatDateCommon } from '@/utils/format';

import ModalCommon from '@/components/Modal/main.vue';
export default {
    name: 'PopUpCompatible',
    components: {
        ModalCommon
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        isShow: {
            type: Boolean,
            default: false
        },
        dataCompatible: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            idInfo: this.id,
            formCompatible: this.dataCompatible,
            configModalCompatible: CONFIG_MODALCOMPATIBLE
        };
    },
    methods: {
        handleCloseCompatible() {
            this.$emit('update:isShow', false);
        },

        hasPrizeLessStatus(param) {
            return param.prize_less_status?.value === '確認中' || param.prize_less_status?.value === '保管';
        },

        handleFormatDate(date, type) {
            return formatDateCommon(date, type);
        }
    },
    watch: {
        id: {
            handler: function (valId) {
                this.idInfo = valId;
            },
            immediate: true
        },
        title: {
            handler: function (valTitle) {
                this.configModalCompatible.title = valTitle;
            },
            immediate: true
        },
        isShow: {
            handler: function (valShow) {
                this.configModalCompatible.model = valShow;
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
